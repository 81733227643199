import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Layout } from 'antd';
import appActions from '@iso/redux/app/actions';
import TopbarNotification from './TopbarNotification';
import TopbarMessage from './TopbarMessage';
import TopbarSearch from './TopbarSearch';
import TopbarUser from './TopbarUser';
import TopbarAddtoCart from './TopbarAddToCart';
import TopbarWrapper from './Topbar.styles';
import { TopbarMenuIcon } from '@iso/config/icon.config';
import Button from "@iso/components/uielements/button";
import {DoubleRightOutlined, QuestionCircleFilled, ShoppingCartOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import Logo from "@iso/components/utility/logo";
import IntlMessages from "@iso/components/utility/intlMessages";
import {hasRole} from "../../shared/helper";
const { Header } = Layout;
const { toggleCollapsed } = appActions;

export default function Topbar({hideButtons, hideUser}) {
  const [selectedItem, setSelectedItem] = React.useState('');
  const customizedTheme = useSelector(
    (state) => state.ThemeSwitcher.topbarTheme
  );
  const { collapsed, openDrawer } = useSelector((state) => state.App);
  const dispatch = useDispatch();
  const handleToggle = React.useCallback(() => dispatch(toggleCollapsed()), [
    dispatch,
  ]);
  const isCollapsed = collapsed && !openDrawer;
  const styling = {
    background: customizedTheme.backgroundColor,
    position: 'fixed',
    width: '100%',
    height: 70,
  };

  const isButtonDisabled = (button) => {

    return false;
  }
  return (
    <TopbarWrapper>
      <Header
        style={styling}
        className={
          isCollapsed ? 'isomorphicTopbar collapsed' : 'isomorphicTopbar'
        }
      >
        <div className="isoLogoWrapper">
          <Link to="/dashboard">
            <IntlMessages id="page.signInTitle" />
          </Link>
        </div>
        <div className="isoLeft">

          <button
            className={
              isCollapsed ? 'triggerBtn menuCollapsed' : 'triggerBtn menuOpen'
            }
            style={{ color: customizedTheme.textColor }}
            onClick={handleToggle}
          >
            <TopbarMenuIcon size={24} color={customizedTheme.textColor} />
          </button>

        </div>

         <ul className="isoRight">
           {!hideButtons &&
               <li className="isoSearch">
                 <Link to={"/dashboard/createProposal"}>
                   <Button disabled={isButtonDisabled("createProposal")} style={{
                     background: "darkgrey",
                     color: "white"
                   }} type={"secondary"}><QuestionCircleFilled/> Anfrage an Agentur ID senden</Button>
                 </Link>
               </li>
           }
           {!hideButtons &&
               <li className="isoSearch">
                 <Link to={"/dashboard/createOrder"}>
                   <Button disabled={isButtonDisabled("createOrder")} type={"primary"}><ShoppingCartOutlined/>Bestellen</Button>
                 </Link>
               </li>
           }





          {/*<li*/}
          {/*  onClick={() => setSelectedItem('notification')}*/}
          {/*  className={selectedItem ? 'isoNotify active' : 'isoNotify'}*/}
          {/*>*/}
          {/*  <TopbarNotification />*/}
          {/*</li>*/}

          {/*<li onClick={() => setSelectedItem('message')} className="isoMsg">*/}
          {/*  <TopbarMessage />*/}
          {/*</li>*/}
          {/*<li onClick={() => setSelectedItem('addToCart')} className="isoCart">*/}
          {/*  <TopbarAddtoCart />*/}
          {/*</li>*/}

          <li onClick={() => setSelectedItem('user')} className="isoUser">
            {!hideUser&&  <TopbarUser />}
          </li>
        </ul>

      </Header>
    </TopbarWrapper>
  );
}
